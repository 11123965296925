import {
	faCss3,
	faGitAlt,
	faHtml5,
	faJsSquare,
	faNodeJs,
	faReact,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Loader from "react-loaders";
import AnimatedLetters from "../AnimatedLetters";

import "./index.scss";

export default function About() {
	const [letterClass, setLetterClass] = useState("text-animate");

	useEffect(() => {
		setTimeout(() => {
			setLetterClass("text-animate-hover");
		}, 3000);
	}, []);

	return (
		<>
			<div className="container about-page">
				<div className="text-zone">
					<h1>
						<AnimatedLetters
							strArray={["A", "b", "o", "u", "t", " ", "m", "e"]}
							idx={15}
							letterClass={letterClass}
						/>
					</h1>
					<p>
						I am an up-and-coming web developer. I have worked and
						developed projects with the MERN stack as well as other
						technologies. I enjoy working front-end and back-end as
						there is always something new and interesting to learn.
					</p>
					<p align="LEFT">
						A brief summary of the technologies I have worked with:
						<p className="highlighted"> CSS</p>,
						<p className="highlighted"> Tailwind</p>,
						<p className="highlighted"> HTML</p>,
						<p className="highlighted"> Javascript</p>,
						<p className="highlighted"> ReactJS</p>,
						<p className="highlighted"> NextJS</p>,
						<p className="highlighted"> NodeJS</p>,
						<p className="highlighted"> Express</p>,
						<p className="highlighted"> MongoDB</p>, developing
						<p className="highlighted"> REST APIs</p>, and of course
						<p className="highlighted"> Git</p>.
					</p>
				</div>
				<div className="stage-cube-cont">
					<div className="cubespinner">
						<div className="face1">
							<FontAwesomeIcon icon={faNodeJs} color="#539E43" />
						</div>
						<div className="face2">
							<FontAwesomeIcon icon={faHtml5} color="#F06529" />
						</div>

						<div className="face3">
							<FontAwesomeIcon
								icon={faJsSquare}
								color="#EFD81D"
							/>
						</div>
						<div className="face4">
							<FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
						</div>
						<div className="face5">
							<FontAwesomeIcon icon={faCss3} color="#28A4D9" />
						</div>
						<div className="face6">
							<FontAwesomeIcon icon={faReact} color="#5ED4F4" />
						</div>
					</div>
				</div>
			</div>
			<Loader type="ball-clip-rotate-multiple" />
		</>
	);
}
