import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Projects from "./components/Projects";

import "./App.scss";

export default function App() {
	return (
		<>
			<Routes>
				<Route element={<Layout />} path="/">
					<Route index element={<Home />}></Route>
					<Route path="about" element={<About />}></Route>
					<Route path="contact" element={<Contact />}></Route>
					<Route path="projects" element={<Projects />}></Route>
				</Route>
			</Routes>
		</>
	);
}
