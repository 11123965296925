import { useEffect, useState } from "react";
import { useRef } from "react";
import Loader from "react-loaders";
import emailjs from "@emailjs/browser";
import AnimatedLetters from "../AnimatedLetters";

import "./index.scss";

export default function Contact() {
	const [letterClass, setLetterClass] = useState("text-animate");
	const form = useRef();

	useEffect(() => {
		setTimeout(() => {
			setLetterClass("text-animate-hover");
		}, 3000);
	}, []);

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm(
				"service_h63qfs7",
				"template_6asb0pj",
				form.current,
				"-uelUS3h5lRbXlHw2"
			)
			.then(
				() => {
					alert("Message successfully sent!");
					window.location.reload(false);
				},
				() => {
					alert("Failed to send the message, please try again");
				}
			);
	};

	return (
		<>
			<div className="container contact-page">
				<div className="text-zone">
					<h1>
						<AnimatedLetters
							letterClass={letterClass}
							strArray={[
								"C",
								"o",
								"n",
								"t",
								"a",
								"c",
								"t",
								" ",
								"m",
								"e",
							]}
							idx={15}
						/>
					</h1>
					<p>
						Hey! Feel free to reach out to me for projects, job
						opportunities or if you just want to chat about web
						development.
					</p>
					<div className="contact-form">
						<form ref={form} onSubmit={sendEmail}>
							<ul>
								<li className="half">
									<input
										placeholder="Name"
										type="text"
										name="name"
										required
									/>
								</li>
								<li className="half">
									<input
										placeholder="Email"
										type="email"
										name="email"
										required
									/>
								</li>
								<li>
									<input
										placeholder="Subject"
										type="text"
										name="subject"
										required
									/>
								</li>
								<li>
									<textarea
										placeholder="Message"
										name="message"
										required
									></textarea>
								</li>
								<li>
									<input
										type="submit"
										className="flat-button"
										value="SEND"
									/>
								</li>
							</ul>
						</form>
					</div>
				</div>
			</div>
			<Loader type="ball-clip-rotate-multiple" />
		</>
	);
}
