import {
	faCss3,
	faHtml5,
	faJsSquare,
	faNodeJs,
	faReact,
} from "@fortawesome/free-brands-svg-icons";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import Loader from "react-loaders";
import Firebase from "../../assets/images/tech/firebase.png";
import MongoDB from "../../assets/images/tech/mongodb.png";
import Express from "../../assets/images/tech/express.png";
import NextJs from "../../assets/images/tech/nextjs.png";
import Tailwind from "../../assets/images/tech/tailwind.png";
import DaisyUI from "../../assets/images/tech/daisyui.png";
import Turso from "../../assets/images/tech/turso.png";

import Project from "./Project";

import "./index.scss";

const projects = [
	{
		projectName: "Purr Tasks",
		description:
			"Purr Tasks is a task manager that allows users to create, edit, delete, and mark tasks as completed. Users can create an account and login after validating their email address. It has a sleek dark mode and a cat theme with each individual task having a random cat image. Every input is validated with error and warning messages.",
		iconTechnologies: [
			{ icon: faHtml5, color: "#F06529" },
			{ icon: faJsSquare, color: "#EFD81D" },
		],
		divTechnologies: [
			{ src: Tailwind },
			{ src: DaisyUI },
			{ src: NextJs },
			{ src: Turso },
		],
		showcaseMode: "purr-tasks",
		github: "https://github.com/jacob-konosx/PurrTasks",
		liveDemo: "https://todo.konosx.dev",
	},
	{
		projectName: "Flix Log",
		description:
			"Flix Log is a website similar to IMDB where users can log in, browse movies, search for them by title and other descriptors like actors, directors, or the year they came out, as well as write reviews, edit user profiles, set ratings for movies and sort your own movies list by rating status and title.",
		iconTechnologies: [
			{ icon: faHtml5, color: "#F06529" },
			{ icon: faCss3, color: "#28A4D9" },
			{ icon: faJsSquare, color: "#EFD81D" },
			{ icon: faReact, color: "#5ED4F4" },
			{ icon: faNodeJs, color: "#539E43" },
		],
		divTechnologies: [{ src: MongoDB }, { src: Express }],
		showcaseMode: "movie-log",
		github: "https://github.com/jacob-konosx/MERN-movie-app",
		liveDemo: "https://flix.konosx.dev",
	},
	{
		projectName: "Cook Book",
		description:
			"One of my first projects! A cookbook that displays recipes. Users are able to log in, view recipes and other users, manage their own recipes: edit and delete them.",
		iconTechnologies: [
			{ icon: faHtml5, color: "#F06529" },
			{ icon: faCss3, color: "#28A4D9" },
			{ icon: faJsSquare, color: "#EFD81D" },
			{ icon: faReact, color: "#5ED4F4" },
		],
		divTechnologies: [{ src: Firebase }],
		showcaseMode: "cook-book",
		github: "https://github.com/jacob-konosx/cook-book",
	},
];

const PROJECTS_PER_PAGE = window.innerWidth <= 768 ? 1 : 2;

function filterProjects(page) {
	return projects.filter(
		(project, i) =>
			i < page * PROJECTS_PER_PAGE &&
			i >= page * PROJECTS_PER_PAGE - PROJECTS_PER_PAGE
	);
}

export default function Projects() {
	const [page, setPage] = useState(1);
	const [selectedProjects, setSelectedProjects] = useState(
		filterProjects(page)
	);

	useEffect(() => {
		setSelectedProjects(filterProjects(page));
	}, [page]);

	return (
		<>
			<div className="container projects-page">
				<div className="text-zone">
					{selectedProjects.length > 0 ? (
						selectedProjects.map((project, i) => (
							<Project key={i} project={project} />
						))
					) : (
						<h2>No projects found on page {page}</h2>
					)}
				</div>

				<Loader type="ball-clip-rotate-multiple" />
			</div>
			<div className="pagination">
				<button
					disabled={page <= 1}
					className={page <= 1 ? "fa-disabled" : ""}
					onClick={() => setPage(page - 1)}
				>
					<FontAwesomeIcon icon={faAngleLeft} />
				</button>
				<button
					disabled={
						page >= Math.ceil(projects.length / PROJECTS_PER_PAGE)
					}
					className={
						page >= Math.ceil(projects.length / PROJECTS_PER_PAGE)
							? "fa-disabled"
							: ""
					}
					onClick={() => setPage(page + 1)}
				>
					<FontAwesomeIcon icon={faAngleRight} />
				</button>
			</div>
		</>
	);
}
