import LogoS from "../../../assets/images/logo-s.png";

import "./index.scss";

export default function Logo() {
	return (
		<div className="logo-container">
			<img className="solid-logo" src={LogoS} alt="logo" />
		</div>
	);
}
