import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AnimatedLetters from "../AnimatedLetters";
import LogoTitle from "../../assets/images/logo-s.png";
import Logo from "./Logo";
import Loader from "react-loaders";

import "./index.scss";

export default function Home() {
	const [letterClass, setLetterClass] = useState("text-animate");
	const nameArray = [
		"a",
		"c",
		"o",
		"b",
		"",
		"K",
		"o",
		"n",
		"o",
		"s",
		"o",
		"n",
		"o",
		"k",
	];
	const jobArray = [
		"w",
		"e",
		"b",
		" ",
		"d",
		"e",
		"v",
		"e",
		"l",
		"o",
		"p",
		"e",
		"r",
	];

	useEffect(() => {
		setTimeout(() => {
			setLetterClass("text-animate-hover");
		}, 4000);
	}, []);

	return (
		<>
			<div className="container home-page">
				<div className="text-zone">
					<h1>
						<span className={letterClass}>H</span>
						<span className={`${letterClass} _12`}>i,</span>
						<br />
						<span className={`${letterClass} _13`}>I</span>
						<span className={`${letterClass} _14`}>'m</span>

						<img src={LogoTitle} alt="developer" />
						<AnimatedLetters
							letterClass={letterClass}
							strArray={nameArray}
							idx={15}
						/>
						<br />
						<AnimatedLetters
							letterClass={letterClass}
							strArray={jobArray}
							idx={15}
						/>
					</h1>
					<h2>Full-stack developer / MERN Stack / Gamer</h2>
					<Link to="/contact" className="flat-button">
						CONTACT ME
					</Link>
				</div>
				<Logo />
			</div>
			<Loader type="ball-clip-rotate-multiple" />
		</>
	);
}
