import { useState } from "react";
import { Lightbox } from "yet-another-react-lightbox";
import CookAccount from "../../../assets/images/projects/cook-book/account.png";
import CookCreation from "../../../assets/images/projects/cook-book/creation.png";
import CookSignin from "../../../assets/images/projects/cook-book/googlesignin.png";
import CookThemes from "../../../assets/images/projects/cook-book/themes.png";
import CookHome from "../../../assets/images/projects/cook-book/home.png";
import CookRecipe from "../../../assets/images/projects/cook-book/recipe.png";

import MovieRecipe from "../../../assets/images/projects/movie-log/account.png";
import MovieAdvSearch from "../../../assets/images/projects/movie-log/adv-search.png";
import MovieHome from "../../../assets/images/projects/movie-log/home.png";
import MovieLogin from "../../../assets/images/projects/movie-log/login.png";
import MovieSearch from "../../../assets/images/projects/movie-log/search.png";
import MovieSignup from "../../../assets/images/projects/movie-log/signup.png";
import MovieSort from "../../../assets/images/projects/movie-log/sort.png";
import MovieUpdate from "../../../assets/images/projects/movie-log/update.png";
import Movie from "../../../assets/images/projects/movie-log/movie.png";

import PurrLogin from "../../../assets/images/projects/purr-tasks/login.png";
import PurrHome from "../../../assets/images/projects/purr-tasks/view.png";
import PurrCreate from "../../../assets/images/projects/purr-tasks/create.png";
import PurrDelete from "../../../assets/images/projects/purr-tasks/deletion.png";
import PurrEdit from "../../../assets/images/projects/purr-tasks/edit.png";
import PurrSignup1 from "../../../assets/images/projects/purr-tasks/signup1.png";
import PurrSignup2 from "../../../assets/images/projects/purr-tasks/signup2.png";
import PurrVerify1 from "../../../assets/images/projects/purr-tasks/verify1.png";
import PurrVerify2 from "../../../assets/images/projects/purr-tasks/verify2.png";
import PurrVerify3 from "../../../assets/images/projects/purr-tasks/verify3.png";
import PurrTask from "../../../assets/images/projects/purr-tasks/task.png";
import PurrToggle from "../../../assets/images/projects/purr-tasks/toggle.png";

import "yet-another-react-lightbox/styles.css";

const projectImages = [
	{
		showcaseMode: "cook-book",
		images: [
			{ src: CookHome },
			{ src: CookAccount },
			{ src: CookCreation },
			{ src: CookSignin },
			{ src: CookThemes },
			{ src: CookRecipe },
		],
	},
	{
		showcaseMode: "movie-log",
		images: [
			{ src: MovieHome },
			{ src: Movie },
			{ src: MovieRecipe },
			{ src: MovieAdvSearch },
			{ src: MovieLogin },
			{ src: MovieSearch },
			{ src: MovieSignup },
			{ src: MovieSort },
			{ src: MovieUpdate },
		],
	},
	{
		showcaseMode: "purr-tasks",
		images: [
			{ src: PurrHome },
			{ src: PurrLogin },
			{ src: PurrCreate },
			{ src: PurrDelete },
			{ src: PurrEdit },
			{ src: PurrSignup1 },
			{ src: PurrSignup2 },
			{ src: PurrVerify1 },
			{ src: PurrVerify2 },
			{ src: PurrVerify3 },
			{ src: PurrTask },
			{ src: PurrToggle },
		],
	},
];

export default function Showcase({ showcaseMode }) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<button className="flat-button" onClick={() => setIsOpen(true)}>
				SHOWCASE
			</button>

			<Lightbox
				open={isOpen}
				close={() => setIsOpen(false)}
				slides={
					projectImages.find(
						(project) => project.showcaseMode === showcaseMode
					).images
				}
			/>
		</>
	);
}
