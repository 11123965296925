import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AnimatedLetters from "../../AnimatedLetters";
import Showcase from "../Showcase";

export default function Project({ project }) {
	const [letterClass, setLetterClass] = useState("text-animate");
	const [loadInClass, setLoadInClass] = useState();

	const {
		projectName,
		description,
		iconTechnologies,
		divTechnologies,
		showcaseMode,
		github,
		liveDemo,
	} = project;

	useEffect(() => {
		setLetterClass("text-animate");
		setLoadInClass("load-in-active");

		setTimeout(() => {
			setLoadInClass("load-in-done");
		}, 300);

		setTimeout(() => {
			setLetterClass("text-animate-hover");
			setLoadInClass("load-in-done");
		}, 2000);
	}, [project]);

	return (
		<>
			<div className="project-container">
				<h1>
					<AnimatedLetters
						letterClass={letterClass}
						strArray={[...projectName]}
						idx={10}
					/>
				</h1>
				<div className={loadInClass}>
					<span className="projectText">
						{description}
						{liveDemo && (
							<>
								{" "}
								<a
									target="_blank"
									rel="noreferrer"
									href={liveDemo}
								>
									Live demo
								</a>
							</>
						)}
					</span>
					<span className="projectText">
						Technologies:{" "}
						{iconTechnologies.map((tech, i) => (
							<FontAwesomeIcon
								icon={tech.icon}
								color={tech.color}
								key={i}
							/>
						))}
						<span className="tech-icons">
							{divTechnologies.map((tech, i) => (
								<img alt="firebase" src={tech.src} key={i} />
							))}
						</span>
					</span>
					<Showcase showcaseMode={showcaseMode} />
					<a
						target="_blank"
						rel="noreferrer"
						href={github}
						className="flat-button"
					>
						GITHUB
					</a>
				</div>
			</div>
		</>
	);
}
