import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faHome,
	faUser,
	faEnvelope,
	faEye,
	faFileLines,
} from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import LogoS from "../../assets/images/logo-s.png";
import LogoSubtitle from "../../assets/images/logo_sub.png";
import CV from "../../assets/files/CV.pdf";

import "./index.scss";

export default function Sidebar() {
	return (
		<div className="nav-bar">
			<Link className="logo" to="/">
				<img src={LogoS} alt="logo" />
				<img className="sub-logo" src={LogoSubtitle} alt="slobodan" />
			</Link>
			<nav>
				<NavLink exact="true" activeclassname="active" to="/">
					<FontAwesomeIcon icon={faHome} color="#4d4d4d" />
				</NavLink>
				<NavLink
					exact="true"
					activeclassname="active"
					className="about-link"
					to="/about"
				>
					<FontAwesomeIcon icon={faUser} color="#4d4d4d" />
				</NavLink>
				<NavLink
					exact="true"
					activeclassname="active"
					className="projects-link"
					to="/projects?page=1"
				>
					<FontAwesomeIcon icon={faEye} color="#4d4d4d" />
				</NavLink>
				<NavLink
					exact="true"
					activeclassname="active"
					className="contact-link"
					to="/contact"
				>
					<FontAwesomeIcon icon={faEnvelope} color="#4d4d4d" />
				</NavLink>
			</nav>
			<ul>
				<li>
					<a
						target="_blank"
						rel="noreferrer"
						href="https://www.linkedin.com/in/jekabs-konosonoks/"
					>
						<FontAwesomeIcon icon={faLinkedin} color="#4d4d4d" />
					</a>
				</li>
				<li>
					<a
						target="_blank"
						rel="noreferrer"
						href="https://github.com/jacob-konosx"
					>
						<FontAwesomeIcon icon={faGithub} color="#4d4d4d" />
					</a>
				</li>
				<li>
					<a
						target="_blank"
						rel="noreferrer"
						href={CV}
					>
						<FontAwesomeIcon icon={faFileLines} color="#4d4d4d" />
					</a>
				</li>
			</ul>
		</div>
	);
}
